import React from 'react';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import './GroupByGrid.scss';
import { FormScreenWrapper, ServiceDrivenViewSet, ViewModal } from '@samc/screen-config-core';
import { GridScreenWrapper } from '@samc/screen-config-core/lib/organisms/GridScreenWrapper/GridScreenWrapper';
import { ViewType } from '@samc/screen-config-api';

interface ModalData {
  formId: string;
  primaryKey: string;
  headerText: string;
}

export const BidGroupManagementGrid = ({
  selectedFilterIds,
  viewId,
  setListViewId,
  setSelectedFilterIds,
  filters,
}: CustomScreenParams): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [modalData] = React.useState<ModalData>();

  const onModalClose = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <GridScreenWrapper
        listViewId={viewId}
        selectedFilterIds={selectedFilterIds}
        setSelectedFilterIds={setSelectedFilterIds}
        filters={filters}
        setListViewId={setListViewId}
        enableGroupByGrid
        groupByFieldName="BidGroup_Name"
        ServiceDrivenViewSet={ServiceDrivenViewSet}
      />
      <ViewModal
        isOpen={isModalOpen}
        onDismiss={onModalClose}
        key={`${modalData?.formId}_${modalData?.primaryKey}`}
        primaryKeyValue={modalData?.primaryKey}
        viewId={modalData?.formId ?? ''}
        viewType={ViewType.Form}
        headerText={modalData?.headerText}
        FormScreenWrapper={FormScreenWrapper}
        GridScreenWrapper={GridScreenWrapper}
        ServiceDrivenViewSet={ServiceDrivenViewSet}
      />
    </>
  );
};

export default BidGroupManagementGrid;
