import { ToastMessage, toastError } from '@samc/react-ui-core';
import { ViewSetAction } from '@samc/screen-config-api/lib/model/ViewSet';
import { useSelectedRowsByGridContext } from '@samc/screen-config-core';
import React from 'react';
import { AddBidRequest } from './AddBidRequest';

interface AddBidRequestModalWrapperProps {
  viewSetId: string;
  primaryKeyValue: string | undefined;
  updateAction: (a: ViewSetAction | undefined) => void;
  refreshViewSet: () => void;
}

const listViewId = 'BidGroupManagement';

export const AddBidRequestModalWrapper = (props: AddBidRequestModalWrapperProps): React.ReactElement => {
  const { viewSetId, primaryKeyValue, updateAction, refreshViewSet } = props;

  const { selectedRowsByGrids } = useSelectedRowsByGridContext(listViewId);

  if (selectedRowsByGrids.length === 0) {
    toastError(<ToastMessage title="Error" message="One or more Assets or Valuation Groups must be selected" />);
    updateAction(undefined);
    return <></>;
  }
  return (
    <AddBidRequest
      viewSetId={viewSetId}
      primaryKeyValue={primaryKeyValue}
      updateAction={updateAction}
      refreshViewSet={refreshViewSet}
    />
  );
};

export default AddBidRequestModalWrapper;
