import React from 'react';

import { GridScreenWrapper, ServiceDrivenViewSet } from '@samc/screen-config-core';
import { EntityEntitlementContextProvider } from '@samc/screen-config-api';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';

const bidRequestItemDomainId = 'BID_BidRequestItem';

export const ProposedValuerApproval = ({
  selectedFilterIds,
  listViewId,
  setListViewId,
  setSelectedFilterIds,
  primaryKeyValue,
  filters,
  tabId,
  tabs,
}: CustomScreenParams): React.ReactElement => {
  const tabBaselineFilterExpression = tabs[tabId].baselineFilterExpression ?? '';
  const appendedFilters = filters ?? [];
  appendedFilters.push(tabBaselineFilterExpression);
  return (
    <EntityEntitlementContextProvider
      domainId={bidRequestItemDomainId}
      entityId={primaryKeyValue}
      targetEntitlementTypes={[]}
    >
      <GridScreenWrapper
        listViewId={listViewId}
        selectedFilterIds={selectedFilterIds}
        setListViewId={setListViewId}
        setSelectedFilterIds={setSelectedFilterIds}
        filters={appendedFilters}
        enableGroupByGrid
        groupByFieldName="BidRequest_Name"
        ServiceDrivenViewSet={ServiceDrivenViewSet}
      />
    </EntityEntitlementContextProvider>
  );
};

export default ProposedValuerApproval;
