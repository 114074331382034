/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { SearchBox } from '@fluentui/react';
import { Grid, GridFieldConfiguration } from '@samc/react-ui-grid';
import { Loader, useDebouncedCallback } from '@samc/react-ui-core';
import { DomainViewMember } from '@samc/screen-config-api';
import { SelectionChangedEvent } from 'ag-grid-community';
import './GridSearch.scss';
import { useLiftAndSiftQuery } from '../../../hooks/useLiftAndSiftQuery/useLiftAndSiftQuery';

const noop = (): void => undefined;

interface GridSearchProps {
  rightGridRows: Record<string, unknown>[];
  onGridRowsChanged: React.Dispatch<React.SetStateAction<Record<string, unknown>[]>>;
  portfolioFilter?: string;
  columns: GridFieldConfiguration[];
  queryMembers: DomainViewMember[];
  label: string;
  searchFields: string[];
  searchFieldsDescription: string;
  domainId: string;
  existingRecordIds: string[];
  primaryKeyName: string;
  children?: React.ReactNode;
}

export const GridSearch = ({
  rightGridRows,
  onGridRowsChanged,
  portfolioFilter,
  columns,
  queryMembers,
  label,
  searchFields,
  searchFieldsDescription,
  domainId,
  existingRecordIds,
  primaryKeyName,
  children,
}: GridSearchProps): React.ReactElement => {
  // to remove selected ids from search
  const ids = React.useMemo(
    () => rightGridRows.map((x) => ` ([${primaryKeyName}] NOT CONTAINS '${x[primaryKeyName]}')`).join(''),
    [rightGridRows],
  );

  const [search, setSearch] = useState('');

  const onSelectionChanged = (e: SelectionChangedEvent): void => {
    const selectedLeftRowsGrid = e.api.getSelectedRows() as Record<string, unknown>[];
    const rows = selectedLeftRowsGrid.filter(
      (row): boolean => !rightGridRows.some((r): boolean => r[primaryKeyName] === row[primaryKeyName]),
    );
    onGridRowsChanged([...rows, ...rightGridRows]);
  };

  const parseFilter = (searchText: string): string => {
    let newFilter = '(';
    if (searchFields.length > 0 && searchText !== '') {
      newFilter += searchFields.map((x) => (x !== undefined ? ` ([${x}] CONTAINS '${searchText}') OR` : '')).join('');
      // remove the trailing OR
      newFilter = `${newFilter.substring(0, newFilter.length - 2)} && `;
    }
    if (ids.length > 0) newFilter += `${ids} &&`;
    newFilter += ` (${portfolioFilter?.replace('=', ' CONTAINS ')}))`;
    return newFilter;
  };

  const result = useLiftAndSiftQuery<Record<string, unknown>>(`${domainId}-search-${search}`, {
    domainId,
    primaryViewId: '-1',
    filterIds: [],
    paging: { start: 0, stop: 1000 },
    adhocListViewMembers: queryMembers,
    sorting: {
      order: 'asc',
      orderBy: { scalarExpression: '[Name]' },
    },
    summaryMode: false,
    adhocFilter: {
      filterName: 'adhoc',
      advancedInd: true,
      expressionLang: 'Centric',
      advancedExpression: parseFilter(search),
    },
  });

  const handleSearch = useDebouncedCallback(
    (_event?: React.ChangeEvent<HTMLInputElement>, v?: string) => {
      setSearch(v ?? '');
    },
    [],
    300,
  );

  return (
    <>
      <div className="search">
        <div>
          <label className="gridLabel">{label}</label>
        </div>
        <div className="searchBoxWithLabel">
          <label className="searchLabel">Search</label>
          <SearchBox onChange={handleSearch} className="customSearchBox" />
        </div>
        <label className="info">
          {`* Search by ${searchFieldsDescription}. Items already added to the group will not be enabled for selection.`}
        </label>
      </div>
      <div className="grid-search">
        <div className="basegrid">
          <Loader isError={result.isError} isLoading={result.isLoading}>
            <>
              {result.data?.Data && (
                <Grid
                  key={`search-grid-${existingRecordIds.length}`}
                  isEditingDefault={false}
                  suppressEditToggle
                  suppressAddRowButton
                  suppressDeleteButton
                  suppressClearFilter={false}
                  suppressExcelExport={false}
                  suppressRowSpacing
                  suppressDeleteConfirmationModal
                  suppressColumnFilters={false}
                  suppressFullScreen={false}
                  data={result.data!.Data.filter(
                    (l) => !rightGridRows.some((r) => r[primaryKeyName] === l[primaryKeyName]),
                  )}
                  fields={columns}
                  onSelectionChanged={onSelectionChanged}
                  gridOptions={{
                    isRowSelectable: (node) =>
                      !existingRecordIds.some((recordId): boolean => node.data[primaryKeyName] === recordId),
                  }}
                  onSubmit={noop}
                />
              )}
            </>
          </Loader>
        </div>
        <div className="basegrid" style={{ paddingLeft: 12 }}>
          {children}
        </div>
      </div>
    </>
  );
};

export default GridSearch;
