export const Fetch = async (
  input: RequestInfo,
  init?: RequestInit | undefined,
  ignoreStatus: number[] = [],
): Promise<Response> => {
  const response = await fetch(input, init);

  if (response.status >= 400 && response.status < 600 && ignoreStatus.indexOf(response.status) === -1) {
    const body = await response.json();

    if (body.developerMessage) {
      throw new Error(`${response.statusText}: ${body.developerMessage.message}`);
    }
    if (body.messages && body.messages.length > 0) {
      throw new Error(`${body.messages[0]}`);
    }
  }
  return response;
};

export default Fetch;
