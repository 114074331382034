import { DomainFilter } from '@samc/screen-config-api';
import { Fetch as _Fetch } from '../Fetch/Fetch';

/**
 * GET the collection of Filters.
 */
export const GetFiltersByIdAsync = async (
  api: string,
  ids: string[],
  requestInit?: RequestInit,
  fetch: typeof _Fetch = _Fetch,
): Promise<DomainFilter[]> => {
  return Promise.all(
    ids.map(async (id): Promise<DomainFilter> => {
      const uri = `${api}/api/v1/Filter/${id}`;

      const response = await fetch(uri, {
        method: 'GET',
        headers: requestInit?.headers,
      });

      return response.json();
    }),
  );
};

export default GetFiltersByIdAsync;
