// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Ext: any;

export const GetHeaders = (): Headers => {
  const headers: Headers = new Headers();

  if (typeof Ext !== 'undefined') {
    headers.set('X-Csrf-Token', Ext.util.Cookies.get('__AF'));
  }
  return headers;
};
export default GetHeaders;
