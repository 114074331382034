import React from 'react';
import {
  ApiContextType,
  ApiProvider as CoreApiContextProvider,
} from '@samc/react-ui-request/lib/contexts/ApiContext/ApiContext';
import { QueryClient as QueryClientClass, QueryClientProvider } from 'react-query';
import {
  useDomain as _useDomain,
  useDomainGetter as _useDomainGetter,
  useFormView as _useFormView,
  useListView as _useListView,
} from '@samc/screen-config-api';
import {
  useHeaderContext as _useHeaderContext,
  useRouterBlockerAndPromptForUnsavedChanges as _useRouterBlockerAndPromptForUnsavedChanges,
} from '@samc/react-ui-history';
import { ShowMessage as _ShowMessage } from '../../functions/ShowMessage/ShowMessage';
// eslint-disable-next-line import/no-cycle
import { useExecuteLiftAndSiftQuery as _useExecuteLiftAndSiftQuery } from '../../hooks/useExecuteLiftAndSiftQuery/useExecuteLiftAndSiftQuery';
// eslint-disable-next-line import/no-cycle
import { useFilters as _useFilters } from '../../hooks/useFilters/useFilters';

const _aggregatorApi = '/_apis/aggregator';
const _folderApi = '/_apis/aggregator/folderapi';
const _liftSiftApi = '/_apis/querybuilder';
const _reportingApi = '/_apis/reporting';
const _strataApi = '/_apis/strata';
const _taskApi = '/taskgateway';
const _bidengageApi = '/_apis/bidengage';
const _queryAuthorizationApi = '/_apis/query-authorization';

const _queryClient = new QueryClientClass();

const _systemInternalIngressPort = 8443;

const _systemOptionalPortfolioIdsExpression = '[Optional_Portfolio_Ids]';
const _systemPortfolioIdExpression = '[Portfolio_Id]';
const _systemPortfolioIdExpressionAlt = '[PortfolioId]';
const _systemPortfolioIdsExpression = '[Portfolio_Ids]';
const _systemSummaryDisplayName = 'SummaryResult';

export interface ApplicationContextType extends ApiContextType {
  AggregatorApi: string;
  FolderApi: string;
  LiftSiftApi: string;
  QueryAuthorizationApi: string;
  QueryClient: QueryClientClass;
  ReportingApi: string;
  StrataApi: string;
  SystemInternalIngressPort: number;
  SystemOptionalPortfolioIdsExpression: string;
  SystemPortfolioIdExpression: string;
  SystemPortfolioIdExpressionAlt: string;
  SystemPortfolioIdsExpression: string;
  SystemSummaryDisplayName: string;
  TaskApi: string;
  useDomain: typeof _useDomain;
  useDomainGetter: typeof _useDomainGetter;
  useExecuteLiftAndSiftQuery: typeof _useExecuteLiftAndSiftQuery;
  useFilters: typeof _useFilters;
  useFormView: typeof _useFormView;
  useHeaderContext: typeof _useHeaderContext;
  useListView: typeof _useListView;
  useRouterBlockerAndPromptForUnsavedChanges: typeof _useRouterBlockerAndPromptForUnsavedChanges;

  TenantIdentifier?: string;
}

/**
 * The idea here is to take a more Dependency Injection style approach to React development, and this context is meant to act like
 * a Service Provider. When you have a component which uses a hook or function that may be useful to change
 * the implementation of (for example demoing with mock data in Storybook, or Jest testing the component
 * and wanting to provide a mock implementation), instead of importing and consuming the hook directly in the
 * component, provide the hook via this context and default its implementation. This allows any component to consume the value
 * from this context, or override and provide its own implementation!
 */
export const ApplicationContext = React.createContext<ApplicationContextType>({
  AggregatorApi: _aggregatorApi,
  CurrentUser: undefined,
  FolderApi: _folderApi,
  LiftSiftApi: _liftSiftApi,
  QueryAuthorizationApi: _queryAuthorizationApi,
  QueryClient: _queryClient,
  ReportingApi: _reportingApi,
  requestInit: undefined,
  ShowMessage: _ShowMessage,
  StrataApi: _strataApi,
  SystemInternalIngressPort: _systemInternalIngressPort,
  SystemOptionalPortfolioIdsExpression: _systemOptionalPortfolioIdsExpression,
  SystemPortfolioIdExpression: _systemPortfolioIdExpression,
  SystemPortfolioIdExpressionAlt: _systemPortfolioIdExpressionAlt,
  SystemPortfolioIdsExpression: _systemPortfolioIdsExpression,
  SystemSummaryDisplayName: _systemSummaryDisplayName,
  TaskApi: _taskApi,
  TenantIdentifier: undefined,
  tenantName: undefined,
  BidEngageApi: _bidengageApi,
  useDomain: _useDomain,
  useDomainGetter: _useDomainGetter,
  useExecuteLiftAndSiftQuery: _useExecuteLiftAndSiftQuery,
  useFilters: _useFilters,
  useFormView: _useFormView,
  useHeaderContext: _useHeaderContext,
  useListView: _useListView,
  useRouterBlockerAndPromptForUnsavedChanges: _useRouterBlockerAndPromptForUnsavedChanges,
});

export const ApplicationContextProvider = ({
  AggregatorApi = _aggregatorApi,
  children,
  CurrentUser,
  FolderApi = _folderApi,
  LiftSiftApi = _liftSiftApi,
  QueryAuthorizationApi = _queryAuthorizationApi,
  QueryClient = _queryClient,
  ReportingApi = _reportingApi,
  requestInit,
  ShowMessage = _ShowMessage,
  StrataApi = _strataApi,
  SystemInternalIngressPort = _systemInternalIngressPort,
  SystemOptionalPortfolioIdsExpression = _systemOptionalPortfolioIdsExpression,
  SystemPortfolioIdExpression = _systemPortfolioIdExpression,
  SystemPortfolioIdExpressionAlt = _systemPortfolioIdExpressionAlt,
  SystemPortfolioIdsExpression = _systemPortfolioIdsExpression,
  SystemSummaryDisplayName = _systemSummaryDisplayName,
  TaskApi = _taskApi,
  TenantIdentifier,
  tenantName,
  BidEngageApi = _bidengageApi,
  useDomain = _useDomain,
  useDomainGetter = _useDomainGetter,
  useExecuteLiftAndSiftQuery = _useExecuteLiftAndSiftQuery,
  useFilters = _useFilters,
  useFormView = _useFormView,
  useHeaderContext = _useHeaderContext,
  useListView = _useListView,
  useRouterBlockerAndPromptForUnsavedChanges = _useRouterBlockerAndPromptForUnsavedChanges,
}: Partial<ApplicationContextType> & {
  children: React.ReactElement;
}): React.ReactElement => {
  const value: ApplicationContextType = React.useMemo(
    () => ({
      AggregatorApi,
      CurrentUser,
      FolderApi,
      LiftSiftApi,
      QueryAuthorizationApi,
      QueryClient,
      ReportingApi,
      requestInit,
      ShowMessage,
      StrataApi,
      SystemInternalIngressPort,
      SystemOptionalPortfolioIdsExpression,
      SystemPortfolioIdExpression,
      SystemPortfolioIdExpressionAlt,
      SystemPortfolioIdsExpression,
      SystemSummaryDisplayName,
      TaskApi,
      TenantIdentifier,
      tenantName,
      BidEngageApi,
      useDomain,
      useDomainGetter,
      useExecuteLiftAndSiftQuery,
      useFilters,
      useFormView,
      useHeaderContext,
      useListView,
      useRouterBlockerAndPromptForUnsavedChanges,
    }),
    [
      AggregatorApi,
      CurrentUser,
      FolderApi,
      LiftSiftApi,
      QueryClient,
      QueryAuthorizationApi,
      ReportingApi,
      requestInit,
      ShowMessage,
      StrataApi,
      SystemInternalIngressPort,
      SystemOptionalPortfolioIdsExpression,
      SystemPortfolioIdExpression,
      SystemPortfolioIdExpressionAlt,
      SystemPortfolioIdsExpression,
      SystemSummaryDisplayName,
      TaskApi,
      TenantIdentifier,
      tenantName,
      BidEngageApi,
      useDomain,
      useDomainGetter,
      useExecuteLiftAndSiftQuery,
      useFilters,
      useFormView,
      useHeaderContext,
      useListView,
      useRouterBlockerAndPromptForUnsavedChanges,
    ],
  );

  return (
    <QueryClientProvider client={QueryClient}>
      <CoreApiContextProvider value={value}>
        <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>
      </CoreApiContextProvider>
    </QueryClientProvider>
  );
};
