import React from 'react';
import { ApiContextType, ApiContext, QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { executeLiftSiftQuery } from '../../BidEngageApi/api/Requests';

export const useLiftAndSiftQuery = <T>(
  key: string,
  requestBody: QueryExecuteRequest,
): UseQueryResult<QueryExecuteResponse<T>> => {
  const api = React.useContext<ApiContextType>(ApiContext);
  const queryResult = useQuery<QueryExecuteResponse<T>>(
    [key],
    () =>
      executeLiftSiftQuery(
        api.LiftSiftApi,
        requestBody,
        api.requestInit,
        api.ShowMessage,
      ) as unknown as QueryExecuteResponse<T>,
    { retry: 2, refetchOnWindowFocus: false },
  );
  return queryResult;
};

export default useLiftAndSiftQuery;
