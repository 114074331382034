import React from 'react';
import { useCurrentUser } from '@samc/single-spa-authentication';
import { ApplicationContextProvider } from './contexts/ApplicationContext/ApplicationContext';
import { ApplicationContextAdapter } from './contexts/ApplicationContextAdapter';

interface Props {
  children: React.ReactElement;
  requestInit: RequestInit;
  tenantIdentifier?: string;
  tenantName: string;
}

export const RootAppContext = ({ children, requestInit, tenantIdentifier, tenantName }: Props): React.ReactElement => {
  const user = useCurrentUser();

  return (
    <ApplicationContextAdapter>
      <ApplicationContextProvider
        CurrentUser={user}
        requestInit={requestInit}
        TenantIdentifier={tenantIdentifier}
        tenantName={tenantName}
      >
        {children}
      </ApplicationContextProvider>
    </ApplicationContextAdapter>
  );
};

export default RootAppContext;
