import { mergeHeaders } from '@samc/react-ui-request';
import { QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { Fetch } from '../Fetch/Fetch';

export const ExecuteLiftAndSiftQuery = async (
  liftSiftApi: string,
  requestBody: QueryExecuteRequest,
  requestInit?: RequestInit,
  showError?: (error: string) => void,
): Promise<QueryExecuteResponse> => {
  try {
    const response = await Fetch(`${liftSiftApi}/api/v2/Query/Execute`, {
      method: 'POST',
      headers: mergeHeaders(requestInit?.headers, {
        'Content-Type': 'application/json; charset=utf-8',
      }),
      body: JSON.stringify(requestBody),
    });

    return response.json();
  } catch (e) {
    if (e instanceof Error) {
      showError?.(e.message);
    }
    throw e;
  }
};

export default ExecuteLiftAndSiftQuery;
