import { QueryExecuteRequest, QueryExecuteResponse } from '@samc/screen-config-api';
import { ExecuteLiftAndSiftQuery } from '../../functions/ExecuteLiftAndSiftQuery/ExecuteLiftAndSiftQuery';
// eslint-disable-next-line import/no-cycle
import { useApplicationContext } from '../useApplicationContext/useApplicationContext';

export const useExecuteLiftAndSiftQuery = (): [(requestBody: QueryExecuteRequest) => Promise<QueryExecuteResponse>] => {
  const { LiftSiftApi, requestInit, ShowMessage } = useApplicationContext();

  const showError = (error: string): void => {
    ShowMessage(error, 'error');
  };

  return [
    (requestBody: QueryExecuteRequest): Promise<QueryExecuteResponse> =>
      ExecuteLiftAndSiftQuery(LiftSiftApi, requestBody, requestInit, showError),
  ];
};

export default useExecuteLiftAndSiftQuery;
