import { DomainFilter } from '@samc/screen-config-api';
import { useQuery, UseQueryResult } from 'react-query';
import { GetFiltersByIdAsync } from '../../functions/GetFiltersByIdAsync/GetFiltersByIdAsync';
// eslint-disable-next-line import/no-cycle
import { useApplicationContext } from '../useApplicationContext/useApplicationContext';

export const FILTERS_KEY = 'Filters';

export const useFilters = (ids: string[]): UseQueryResult<DomainFilter[]> => {
  const { AggregatorApi, requestInit } = useApplicationContext();

  const queryResult = useQuery<DomainFilter[]>(
    [FILTERS_KEY, ...ids],
    () => GetFiltersByIdAsync(AggregatorApi, ids, requestInit),
    {
      retry: false,
    },
  );

  return queryResult;
};

export default useFilters;
