import { mergeHeaders } from '@samc/react-ui-request';
import { QueryExecuteRequest, QueryExecuteResponse, TaskRequest, TaskResponse } from '@samc/screen-config-api';

import { GetHeaders } from './GetHeader';

export const baseUrl = `${
  window.location.origin.includes('localhost') ? 'https://vmsnext-dev.situsamc.com' : window.location.origin
}`;

export const fetchApi = async (
  input: RequestInfo,
  init?: RequestInit | undefined,
  ignoreStatus: number[] = [],
): Promise<Response> => {
  const response = await fetch(input, init);
  if (response.status >= 400 && response.status < 600 && ignoreStatus.indexOf(response.status) === -1) {
    const body = await response.json();
    if (body.developerMessage) {
      throw new Error(`${response.statusText}: ${body.developerMessage.message}`);
    }
    if (body.messages && body.messages.length > 0) {
      throw new Error(`${body.messages[0]}`);
    }
  }
  return response;
};

export const submitTask = async (
  domainId: string,
  fieldConfigurationId: string,
  requestIdentifier: string,
  payload: TaskRequest['payload'],
  requestInit: RequestInit,
  trialPost = true,
  fetch = fetchApi,
  overrideCodes: string[] = [],
): Promise<[TaskResponse, TaskRequest]> => {
  const request: TaskRequest & { postFlag: number } = {
    domainId,
    fieldConfigurationId,
    requestIdentifier,
    postFlag: trialPost ? 2 : 0,
    payload,
  };

  if (overrideCodes.length) request.overrideCodes = overrideCodes;
  const response = await fetch(`${baseUrl}/taskgateway/api/v2/task`, {
    method: 'POST',
    headers: mergeHeaders(GetHeaders(), requestInit?.headers, {
      'Content-Type': 'application/json; charset=utf-8',
    }),
    body: JSON.stringify(request),
  });
  return [await response.json(), request];
};

export const getData = async <T = Record<string, unknown>>(url: string, requestInit: RequestInit): Promise<T> => {
  const response = await fetchApi(`${baseUrl}/_apis/bidengage/${url}`, {
    method: 'GET',
    headers: mergeHeaders(GetHeaders(), requestInit?.headers),
  });
  return response.json();
};

export const apiProvider = {
  getData,
  submitTask,
};

/**
 * Posts a v2/Query/Execute request to Lift and Sift.
 * The logic here is taken from screen-config but without the cacheId stuff we do over there because it was causing us
 * to occasionally get stale results back when we wouldn't expect it. This function won't attach a cacheId of its own volition.
 *
 * @param liftSiftApi The url of the Lift and Sift endpoint.
 * @param requestBody The body to attach to the POST request.
 * @param requestInit
 * @param showError Error handling function that will be called on error.
 * @returns The Lift and Sift Execute response.
 */
export const executeLiftSiftQuery = async (
  liftSiftApi: string,
  requestBody: QueryExecuteRequest,
  requestInit?: RequestInit,
  showError?: (error: string) => void,
): Promise<QueryExecuteResponse> => {
  try {
    const response = await fetchApi(`${liftSiftApi}/api/v2/Query/Execute`, {
      method: 'POST',
      headers: mergeHeaders(GetHeaders(), requestInit?.headers, {
        'Content-Type': 'application/json; charset=utf-8',
      }),
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (e) {
    if (e instanceof Error) {
      showError?.(e.message);
    }
    throw e;
  }
};
