import React from 'react';
import { CustomScreenParams } from '@samc/screen-config-core/lib/contexts/TabOverrideContext/TabOverrideContext';
import { ServiceDrivenViewSet } from '@samc/screen-config-core';
import { GridScreenWrapper } from '@samc/screen-config-core/lib/organisms/GridScreenWrapper/GridScreenWrapper';

export const BidGroupManagementGrid = ({
  selectedFilterIds,
  viewId,
  setListViewId,
  setSelectedFilterIds,
  filters,
}: CustomScreenParams): React.ReactElement => {
  return (
    <GridScreenWrapper
      listViewId={viewId}
      selectedFilterIds={selectedFilterIds}
      setSelectedFilterIds={setSelectedFilterIds}
      filters={filters}
      setListViewId={setListViewId}
      enableGroupByGrid
      groupByFieldName="BidRequest_Name"
      ServiceDrivenViewSet={ServiceDrivenViewSet}
      gridDisplayOptions={{ suppressAddRowButton: true, suppressEditToggle: true, suppressDeleteButton: true }}
    />
  );
};

export default BidGroupManagementGrid;
