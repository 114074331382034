import { Buffer } from 'buffer';

export const decodeBase64 = (str: string): string => Buffer.from(str, 'base64').toString('binary');
export const encodeBase64 = (str: string): string => Buffer.from(str, 'binary').toString('base64');

export const decodeBase64ToObject = <T>(str: string): T => JSON.parse(decodeBase64(str)) as T;
export const encodeBase64FromObject = (o: unknown): string => encodeBase64(JSON.stringify(o));

export function createGuid(): string {
  /* eslint-disable no-bitwise */
  /* eslint-disable one-var */
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
