import React from 'react';
import { ConfirmationDialog, toastError, ToastMessage, toastSuccess } from '@samc/react-ui-core';
import { ViewSetAction } from '@samc/screen-config-api/lib/model/ViewSet';
import { useSelectedRowsByGridContext } from '@samc/screen-config-core';
import { TaskRequest, TaskResponse, TaskStatus } from '@samc/screen-config-api';
import TaskResponsePopup from '@samc/screen-config-core/lib/molecules/TaskResponsePopup/TaskResponsePopup';
import { BidRequestItem } from '../../../BidEngageApi/models/BidRequestItem';
import { useApiContext } from '../../../hooks/useApiContext';
import { apiProvider } from '../../../BidEngageApi/api/Requests';
import { BidRequestItemActions } from '../../../BidEngageApi/models/BidRequestItemActions';

interface BidRequestItemActionHandlerProps {
  updateAction: (a: ViewSetAction | undefined) => void;
  refreshViewSet: () => void;
  actionFlag: keyof BidRequestItemActions;
  requestIdentifier: string;
  dialogDetail: string;
  dialogTitle: string;
  listViewId: string;
}

const emptyResponsesArray: TaskResponse[] = [];
const defaultRequestsArray: TaskRequest[] = [
  {
    domainId: '',
    requestIdentifier: '',
    payload: {},
  },
];

const BidRequestItemActionHandler = (props: BidRequestItemActionHandlerProps): React.ReactElement => {
  const { updateAction, refreshViewSet, actionFlag, requestIdentifier, dialogDetail, dialogTitle, listViewId } = props;

  const { requestInit = {} } = useApiContext();

  const [requestResponse, setRequestResponse] = React.useState<{
    request: TaskRequest[];
    response: TaskResponse[];
  }>();
  const setVisible = React.useCallback((): void => setRequestResponse(undefined), []);
  const { selectedRowsByGrids } = useSelectedRowsByGridContext(listViewId);

  const { selectedRows } = selectedRowsByGrids[0] ?? [];

  const bidRequestItemPayload = React.useMemo(
    () =>
      selectedRows
        .filter((u): boolean => !u.__DELETED__)
        .reduce((acc, row): Record<string, BidRequestItem> => {
          return {
            ...acc,
            [`${row.Id}`]: {
              [actionFlag]: true,
            },
          } as Record<string, BidRequestItem>;
        }, {}),
    [selectedRows],
  );

  const submitForm = React.useCallback(
    async (overrideCodes?: string[]): Promise<void> => {
      const showError = (): void => {
        toastError(<ToastMessage title="Error" message="Something went wrong" />);
      };
      apiProvider
        .submitTask(
          'BID_BidRequestItem',
          'BID_BidRequestItem',
          requestIdentifier,
          bidRequestItemPayload as TaskRequest['payload'],
          requestInit ?? {},
          false,
          undefined,
          overrideCodes,
        )
        .then(([response, request]) => {
          if (response.statusCode !== TaskStatus.Completed) {
            if (!overrideCodes) {
              setRequestResponse({ request: [request], response: [response] });
            }
          } else {
            toastSuccess(<ToastMessage title="Success" message="Bid Request saved successfully" />);
            updateAction(undefined);
            refreshViewSet();
          }
        })
        .catch(showError);
    },
    [requestInit, bidRequestItemPayload],
  );

  const onResponse = (stay: boolean): void => {
    if (!stay) {
      updateAction(undefined);
    } else {
      submitForm();
    }
  };

  if (selectedRowsByGrids.length === 0) {
    toastError(<ToastMessage title="Error" message="At least one Bid Item must be selected to continue." />);
    updateAction(undefined);
    return <></>;
  }

  return (
    <>
      <ConfirmationDialog
        giveAnswer={onResponse}
        detail={dialogDetail}
        confirmText="Continue with Save"
        cancelText="Exit without saving"
        title={dialogTitle}
      />
      <TaskResponsePopup
        responses={requestResponse?.response ?? emptyResponsesArray}
        requests={requestResponse?.request ? requestResponse.request : defaultRequestsArray}
        visible={requestResponse?.response !== undefined}
        setVisible={setVisible}
        submitWithOverrides={submitForm}
      />
    </>
  );
};

export default BidRequestItemActionHandler;
